import request from '../utils/apiRequest'
// 做题页面
export function getNewExerciseList(data) {
  return request({
    // url: '/api/getNewExerciseList',
    url: '/exam/zkbwx/getNewExerciseList',
    method: 'POST',
    data
  })
}
// 交卷
export function submitResult(data) {
  return request({
    // url: '/api/submitResult',
    url: '/exam/zkbwx/submitResult',
    method: 'POST',
    data
  })
}
